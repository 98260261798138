import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchMetrics } from "../../api/metrics";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

export default function Metrics({ selectedDate }) {
  const [expanded, setExpanded] = useState(false);
  const [metricsData, setMetricsData] = useState(null);
  const theme = useTheme();

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedDate = selectedDate
          ? moment(selectedDate).format("YYYYMMDD")
          : moment().format("YYYYMMDD");
        const data = await fetchMetrics(formattedDate);
        setMetricsData(data);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        setMetricsData(null);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (!metricsData) {
    return null; // or render a loading state
  }

  const cardData = [
    { title: "TOTAL", value: metricsData.total },
    { title: "PENDING PICKUP", value: metricsData.pendingPickup },
    { title: "INTRANSIT", value: metricsData.intransit },
    { title: "ON TIME", value: metricsData.ontime },
    { title: "LATE", value: metricsData.late },
    { title: "MISSED", value: metricsData.missed },
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionToggle}
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        background: theme.palette.background.default,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
      >
        Metrics
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            padding: "20px",
            background: theme.palette.background.default,
          }}
        >
          {cardData.map((data, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1 0 auto",
                textAlign: "center",
                width: "10%",
                border: theme.palette.border.main,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  background: theme.palette.background.default,
                }}
              >
                <CardContent>
                  <Typography
                    component='div'
                    color={theme.palette.text.primary}
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: theme.palette.text.primary,
                      background: theme.palette.background.default,
                    }}
                  >
                    {data.title} :
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: theme.palette.text.primary,
                      background: theme.palette.background.default,
                    }}
                    variant='subtitle1'
                    color={theme.palette.text.primary}
                    component='div'
                  >
                    {data.value}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
