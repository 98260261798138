import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Metrics from "../../components/metrics/Metrics";
import { Typography } from "@mui/material";
import Search from "../../components/Search/Search";
import ShipmentTable from "../../components/shipmentTable/ShipmentTable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { fetchShipments } from "../../api/shipmentTable";

const DashBoard = ({ accountInfo, onThemeChange, onLogout }) => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const theme = useTheme();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isExportDisabled, setIsExportDisabled] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleApplyFilters = (filters, isClearingFilters = false) => {
    if (isClearingFilters) {
      // If clearing filters, update the appliedFilters state with the provided filters
      setAppliedFilters(filters);
    } else {
      // If applying filters, merge the provided filters with the existing appliedFilters state
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }
    setAppliedFiltersCount(filters.AppliedFiltersCount || 0);
  };

  const handleExportButtonClick = () => {
    setExportPopupOpen(true);
  };

  const handleExportPopupClose = (format) => {
    setExportPopupOpen(false);
    if (format) {
      exportData(format);
    }
  };

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString) {
      const formattedDateTime = moment(dateTimeString, "YYYYMMDDHHmmss").format("MM-DD-YY HH:mm");
      return formattedDateTime !== "Invalid date" ? formattedDateTime : "";
    }
    return "";
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const formattedDate = moment(dateString, "YYYYMMDDHHmmss").format("MM-DD-YY");
      return formattedDate !== "Invalid date" ? formattedDate : "";
    }
    return "";
  };

  const handleRowsChange = (newRows) => {
    console.log("Rows data:", newRows);
    setRows(newRows);
  };

  const handleFetchError = (hasError) => {
    setIsExportDisabled(hasError);
  };

  const formatOriginalRequestDate = (dateString) => {
    if (dateString) {
      let formattedDate;
      // Try parsing as "DD-MMM-YYYY"
      formattedDate = moment(dateString, "DD-MMM-YYYY", true);

      // If not valid, try parsing as "YYYY-MM-DD"
      if (!formattedDate.isValid()) {
        formattedDate = moment(dateString, "YYYY-MM-DD", true);
      }

      // If still not valid, return empty string
      if (!formattedDate.isValid()) {
        return "";
      }

      // Format the date as "MM-DD-YY"
      return formattedDate.format("MM-DD-YY");
    }
    return "";
  };

  const exportData = async (format) => {
    try {
      setIsExportDisabled(true); // Disable export button while fetching

      const payload = {
        ...appliedFilters,
        SortBy: "DeliveryAppointment",
        SortOrder: "descending",
      };

      const response = await fetchShipments(payload);
      const formattedData = response.shipments.map((row) => ({
        "Delivery Number": row.DeliveryNumber,
        "NB Number": row.NBNumber,
        "Stop Sequence": row.StopSequence,
        "Customer Name": row.CustomerName,
        City: row.City,
        State: row.State,
        "Data Source": row.DataSource,
        "Early Delivery Date": formatDateTime(row.EarlyDeliveryDt),
        "Delivery Appointment": formatDateTime(row.DeliveryAppointment),
        "Delivery ETA": formatDateTime(row.DeliveryEta),
        "Delivery Time": formatDateTime(row.DeliveryTime),
        "Created By": row.CreatedBy,
        "Created Time": formatDateTime(row.CreatedTime),
        "Updated By":
          row.ShipmentDetails && row.ShipmentDetails.UpdatedBy
            ? row.ShipmentDetails.UpdatedBy
            : row.UpdatedBy,
        "Updated Time": formatDateTime(row.UpdatedTime),
        "PO Number": row.ShipmentDetails.PONumber,
        "Customer Region": row.ShipmentDetails.CustomerRegion,
        Commodity: row.ShipmentDetails.Commodity,
        "Pickup Appointment": formatDateTime(row.ShipmentDetails.PickupAppointment),
        "Appointment Start DateTime": formatDateTime(row.ShipmentDetails.AppointStartDateTime),
        "Appointment End DateTime": formatDateTime(row.ShipmentDetails.AppointEndDateTime),
        "Transit Time": row.ShipmentDetails.TransitTime,
        Distance: row.ShipmentDetails.Distance,
        Preload: row.ShipmentDetails.Preload,
        "Pickup ETA": formatDateTime(row.ShipmentDetails.PickupEta),
        "Pickup Time": formatDateTime(row.ShipmentDetails.PickupTime),
        "Transaction Datetime": formatDateTime(row.ShipmentDetails.TransactionDatetime),
        "Ship Date": formatDateTime(row.ShipmentDetails?.ShipDate),
        "Origin Name": row.ShipmentDetails.OrgName,
        "Original Request Date": formatOriginalRequestDate(
          row.OriginalRequestDate || row.ShipmentDetails?.OriginalRequestDate
        ),
        Carrier: row.ShipmentDetails.Carrier,
        "Transport Mode": row.ShipmentDetails.TransportMode,
        "Delivery Status": row.DeliveryStatus,
        "Pickup Status": row.PickupStatus,
        Warehouse: row.WareHouseNbr,
        "Customer ID": row.DestinationLocationGID,
        "Phone Number": row.ContactDetails,
      }));

      console.log("Export data:", formattedData);

      if (format === "xlsx") {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Adjust column widths
        const columnWidths = [
          { wch: 20 }, // Delivery Number
          { wch: 15 }, // NB Number
          { wch: 15 }, // Stop Sequence
          { wch: 25 }, // Customer Name
          { wch: 20 }, // City
          { wch: 10 }, // State
          { wch: 15 }, // Data Source
          { wch: 20 }, // Early Delivery Date
          { wch: 20 }, // Delivery Appointment
          { wch: 20 }, // Delivery ETA
          { wch: 20 }, // Delivery Time
          { wch: 15 }, // Created By
          { wch: 20 }, // Created Time
          { wch: 15 }, // Updated By
          { wch: 20 }, // Updated Time
          { wch: 25 }, // PO Number
          { wch: 20 }, // Customer Region
          { wch: 20 }, // Commodity
          { wch: 20 }, // Pickup Appointment
          { wch: 25 }, // Appointment Start DateTime
          { wch: 25 }, // Appointment End DateTime
          { wch: 15 }, // Transit Time
          { wch: 15 }, // Distance
          { wch: 15 }, // Preload
          { wch: 20 }, // Pickup ETA
          { wch: 20 }, // Pickup Time
          { wch: 25 }, // Transaction Datetime
          { wch: 15 }, // Ship Date
          { wch: 25 }, // Origin Name
          { wch: 25 }, // Original Request Date
          { wch: 15 }, // Carrier
          { wch: 20 }, // Transport Mode
          { wch: 20 }, // Delivery Status
          { wch: 20 }, // Pickup Status
          { wch: 15 }, // Warehouse
          { wch: 15 }, // Customer ID
          { wch: 20 }, // Phone Number
        ];

        worksheet["!cols"] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Shipments");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "shipments.xlsx");
      } else if (format === "csv") {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(formattedData));
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "shipments.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setIsExportDisabled(false); // Re-enable export button
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: theme.palette.background.default,
      }}
    >
      <Header accountInfo={accountInfo} onThemeChange={onThemeChange} onLogout={onLogout} />
      {!isOnline && (
        <Box
          sx={{
            backgroundColor: "error.main",
            color: "error.contrastText",
            padding: "10px",
            textAlign: "center",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
        >
          Network not available. Please try Again Later.
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          paddingBottom: 0,
        }}
      >
        <Search
          onApplyFilters={handleApplyFilters}
          onExportButtonClick={handleExportButtonClick}
          exportPopupOpen={exportPopupOpen}
          onExportPopupClose={handleExportPopupClose}
          selectedDate={appliedFilters?.SelectedDate}
          appliedFiltersCount={appliedFiltersCount}
          appliedFilters={appliedFilters}
          isExportDisabled={isExportDisabled}
        />
        <ShipmentTable
          appliedFilters={appliedFilters}
          onRowsChange={handleRowsChange}
          accountInfo={accountInfo}
          onFetchError={handleFetchError}
        />
      </Box>
      <Metrics selectedDate={appliedFilters?.SelectedDate} />
    </Box>
  );
};

export default DashBoard;
