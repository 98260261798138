import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import Filter from "../filter/Filter";
import { Typography } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import Export from "../export/Export";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

const Search = ({
  onApplyFilters,
  onExportButtonClick,
  exportPopupOpen,
  onExportPopupClose,
  selectedDate,
  appliedFiltersCount,
  appliedFilters,
  isExportDisabled,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const today = moment().format("YYYYMMDD");
    onApplyFilters({
      SelectedDate: today,
      AppliedFiltersCount: 1,
    });
  }, []);

  const handleSearchTypeChange = (event) => {
    const searchType = event.target.value;
    setSearchType(searchType === "" ? null : searchType);
    setSearchQuery("");

    if (searchType === "") {
      const filters = {
        SearchQuery: "",
        SelectedDate: moment().format("YYYYMMDD"),
        AppliedFiltersCount: 1, // Set to 1 because of the default date filter
      };
      onApplyFilters(filters);
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    const searchFilters = {
      SearchQuery: searchType ? `${searchType}:${searchQuery}` : "",
      SelectedDate: selectedDate || moment().format("YYYYMMDD"),
    };

    const updatedFilters = {
      ...appliedFilters,
      ...searchFilters,
    };

    onApplyFilters(updatedFilters);
  };

  const handleClearFilters = () => {
    setSearchQuery("");
    setSearchType("");

    const filters = {
      SearchQuery: "",
      SelectedDate: moment().format("YYYYMMDD"),
      AppliedFiltersCount: 1, // Set to 1 because of the default date filter
    };

    onApplyFilters(filters, true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}-${day}-${year} ${hours}:${minutes}`;
  };

  return (
    <Box
      sx={{
        padding: "0px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}>
        <Select
          value={searchType || ""}
          onChange={handleSearchTypeChange}
          displayEmpty
          renderValue={(selected) => (selected ? selected : "Select Search Type")}
          sx={{
            fontFamily: "Poppins, sans-serif",
            padding: "10px",
            width: "150px",
            height: "48px",
            borderRadius: "4px",
            color: theme.palette.text.primary,
            "& fieldset": {
              border: theme.palette.border.main,
            },
          }}
        >
          <MenuItem value='PONumber'>PO Number</MenuItem>
          <MenuItem value='DeliveryNumber'>Delivery Number</MenuItem>
          <MenuItem value='NBNumber'>NB Number</MenuItem>
          <MenuItem value='' onClick={handleClearFilters}>
            Clear
          </MenuItem>
        </Select>
        <OutlinedInput
          placeholder={searchType ? `Search by ${searchType}` : "Search by NB/Delivery/PO Number"}
          type='text'
          value={searchQuery}
          onChange={handleSearchQueryChange}
          sx={{
            fontFamily: "Poppins, sans-serif",
            padding: "10px",
            width: "528px",
            height: "48px",
            borderRadius: "4px",
            color: theme.palette.text.primary,
            "& fieldset": {
              border: theme.palette.border.main,
            },
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleSearch}>
                <SearchIcon sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant='contained'
          onClick={toggleSidebar}
          startIcon={<FilterAltOutlinedIcon />}
          endIcon={
            appliedFiltersCount > 0 && (
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: theme.palette.button.main,
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  marginLeft: "8px",
                  "& > span": {
                    transform: "scale(0.8)",
                  },
                }}
              >
                <span>{appliedFiltersCount}</span>
              </Box>
            )
          }
          sx={{
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            color: theme.palette.text.buttonhover,
            backgroundColor: theme.palette.button.main,
            height: "48px",
            "&:hover": {
              backgroundColor: theme.palette.buttonhover.main,
            },
          }}
        >
          Filters
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Typography
          variant='body2'
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: theme.palette.text.primary,
          }}
        >
          Last updated at:
        </Typography>
        <Typography
          variant='body2'
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: theme.palette.text.primary,
          }}
        >
          {formatDate(currentDate)}
        </Typography>

        <Button
          variant='outlined'
          disabled={isExportDisabled}
          onClick={onExportButtonClick}
          startIcon={<IosShareIcon />}
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: theme.palette.text.button,
            borderColor: theme.palette.border.main,
            "&:hover": {
              backgroundColor: theme.palette.buttonhover.main,
              color: theme.palette.text.buttonhover,
              borderColor: theme.palette.buttonhover.main,
            },
          }}
        >
          Export
        </Button>
      </Box>
      <Export open={exportPopupOpen} handleClose={onExportPopupClose} />
      <Filter
        isOpen={isOpen}
        toggleDrawer={toggleSidebar}
        onApplyFilters={onApplyFilters}
        data-testid='Filter'
        appliedFiltersCount={appliedFiltersCount}
      />
    </Box>
  );
};

export default Search;
