import axiosInstance from './axios';
import config from './config';

export const fetchCustomerRegions = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      `${config.endpoints.customer_regions_url}?customerId=${customerId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching customer regions:', error);
    throw error;
  }
};
