import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchCustomers } from "../../api/customers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { fetchCustomerRegions } from "../../api/customerRegions";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

const today = dayjs();
const yesterday = today.subtract(1, "day");
const dayBeforeYesterday = today.subtract(2, "day");

const Sidebar = ({ isOpen, toggleDrawer, onApplyFilters, initialDate }) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState(null);
  const [customerRegions, setCustomerRegions] = useState([]);
  const [selectedCustomerRegions, setSelectedCustomerRegions] = useState([]);
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(initialDate));
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState(null);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    setSelectedDate(dayjs(initialDate));
  }, [initialDate]);

  useEffect(() => {
    const calculateAppliedFiltersCount = () => {
      let count = 0;
      if (selectedDeliveryStatus) count++;
      if (selectedDate) count++;
      if (selectedCustomerId) count++;
      if (selectedCustomerRegions.length > 0) count++;
      setAppliedFiltersCount(count);
    };

    calculateAppliedFiltersCount();
  }, [selectedDeliveryStatus, selectedDate, selectedCustomerId, selectedCustomerRegions]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateError(false);
  };

  const handleClearFilters = () => {
    setSelectedDeliveryStatus(null);
    setSelectedDate(dayjs());
    setSelectedCustomerName(null);
    setSelectedCustomerRegions([]);
    setSelectedCustomerId(null);
    setDateError(false);

    const filters = {
      SelectedDate: moment().format("YYYYMMDD"),
      CustomerName: null,
      CustomerRegion: null,
      DeliveryStatus: null,
      AppliedFiltersCount: 1,
    };

    onApplyFilters(filters);
    toggleDrawer();
  };

  const handleDeliveryStatusChange = (status) => {
    if (selectedDeliveryStatus === status) {
      setSelectedDeliveryStatus(null);
    } else {
      setSelectedDeliveryStatus(status);
    }
  };

  const handleCustomerRegionChange = (region) => {
    const index = selectedCustomerRegions.indexOf(region);
    if (index === -1) {
      setSelectedCustomerRegions([...selectedCustomerRegions, region]);
    } else {
      setSelectedCustomerRegions(selectedCustomerRegions.filter((r) => r !== region));
    }
  };

  const handleApplyFilters = () => {
    if (
      selectedDate &&
      !(
        selectedDate.isSame(today, "day") ||
        selectedDate.isSame(yesterday, "day") ||
        selectedDate.isSame(dayBeforeYesterday, "day")
      )
    ) {
      setDateError(true);
      return;
    }

    const filters = {
      SelectedDate: selectedDate ? selectedDate.format("YYYYMMDD") : null,
      CustomerName: selectedCustomerName || null,
      CustomerRegion: selectedCustomerRegions.length > 0 ? selectedCustomerRegions.join(",") : null,
      DeliveryStatus: selectedDeliveryStatus || null,
      AppliedFiltersCount: appliedFiltersCount,
    };

    onApplyFilters(filters);
    toggleDrawer();
  };

  useEffect(() => {
    const getCustomerRegions = async () => {
      setLoadingRegions(true);
      setCustomerRegions([]);

      if (selectedCustomerId) {
        try {
          const data = await fetchCustomerRegions(selectedCustomerId);
          setCustomerRegions(data);
        } catch (error) {
          console.error("Error fetching customer regions:", error);
          setCustomerRegions(null);
        }
      }

      setLoadingRegions(false);
    };

    getCustomerRegions();
  }, [selectedCustomerId]);

  useEffect(() => {
    const getCustomers = async () => {
      try {
        const data = await fetchCustomers();
        setCustomers(data);
      } catch (error) {
        console.error("Error fetching customers:", error);
        setCustomers(null);
      }
    };

    getCustomers();
  }, []);

  const handleCustomerChange = (customerId, customerName) => {
    if (selectedCustomerId === customerId) {
      setSelectedCustomerId(null);
      setSelectedCustomerName(null);
      setSelectedCustomerRegions([]);
    } else {
      setSelectedCustomerId(customerId);
      setSelectedCustomerName(customerName);
      setSelectedCustomerRegions([]);
    }
  };

  const theme = useTheme();

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          width: 350,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "28px",
              fontWeight: 400,
              lineHeight: "36px",
              letterSpacing: "0px",
              textAlign: "left",
              margin: "20px",
            }}
            variant='h6'
            gutterBottom
          >
            Filters
          </Typography>

          <Accordion
            sx={{
              border: "1px solid #E8F2FF",
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{ height: "50px" }}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedCustomerId ? "bold" : "normal",
                }}
              >
                Customer
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                },
              }}
            >
              {customers === null ? (
                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                  Customers not found
                </Typography>
              ) : customers && customers.length > 0 ? (
                customers.map((customer) => (
                  <FormControlLabel
                    key={customer.id}
                    control={
                      <Checkbox
                        sx={{
                          color: "#006098",
                          "&.Mui-checked": { color: "#006098" },
                        }}
                        checked={selectedCustomerId === customer.id}
                        onChange={() => handleCustomerChange(customer.id, customer.name)}
                      />
                    }
                    label={
                      <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                        {customer.name}
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                  />
                ))
              ) : (
                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                  No customers available
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              border: "1px solid #E8F2FF",
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
            disabled={
              !selectedCustomerId ||
              loadingRegions ||
              (customerRegions !== null && customerRegions && customerRegions.length === 0)
            }
          >
            <AccordionSummary
              sx={{ height: "50px" }}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedCustomerRegions.length > 0 ? "bold" : "normal",
                }}
              >
                Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                },
              }}
            >
              {customerRegions === null ? (
                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                  Customer regions not found
                </Typography>
              ) : customerRegions && customerRegions.length > 0 ? (
                customerRegions.map((region) => (
                  <FormControlLabel
                    key={region.id}
                    control={
                      <Checkbox
                        sx={{
                          color: "#006098",
                          "&.Mui-checked": { color: "#006098" },
                        }}
                        checked={selectedCustomerRegions.includes(region.region)}
                        onChange={() => handleCustomerRegionChange(region.region)}
                      />
                    }
                    label={
                      <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                        {region.region}
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                  />
                ))
              ) : (
                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                  No regions available
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              border: "1px solid #E8F2FF",
              marginBottom: "10px",
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{ height: "50px" }}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedDeliveryStatus ? "bold" : "normal",
                }}
              >
                Delivery Status
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Blank"}
                    onChange={() => handleDeliveryStatusChange("Blank")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Blank</Typography>}
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "On Time"}
                    onChange={() => handleDeliveryStatusChange("On Time")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>On Time</Typography>}
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Late"}
                    onChange={() => handleDeliveryStatusChange("Late")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Late</Typography>}
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Missed"}
                    onChange={() => handleDeliveryStatusChange("Missed")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Missed</Typography>}
              />
            </AccordionDetails>
          </Accordion>

          <Box
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: selectedDate ? "bold" : "normal",
              }}
            >
              Select Delivery Appointment
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label='Select a date'
                  inputFormat='MM/DD/YYYY'
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(props) => <TextField {...props} sx={{ width: "100%" }} />}
                  shouldDisableDate={(date) => {
                    const currentDate = dayjs(date);
                    return !(
                      currentDate.isSame(today, "day") ||
                      currentDate.isSame(yesterday, "day") ||
                      currentDate.isSame(dayBeforeYesterday, "day")
                    );
                  }}
                  sx={{ width: "100%" }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {dateError && (
              <Typography sx={{ color: "red", marginTop: "8px" }}>
                Please select a valid date (today, yesterday, or the day before yesterday).
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Button
            variant='text'
            sx={{
              textTransform: "none",
              color: theme.palette.text.primary,
              marginRight: "16px",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
          <Button
            variant='text'
            sx={{
              textTransform: "none",
              color: theme.palette.text.primary,
              marginRight: "16px",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={toggleDrawer}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{
              textTransform: "none",
              borderRadius: "80px",
              bgcolor: theme.palette.button.main,
              "&:hover": { bgcolor: theme.palette.buttonhover.main },
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
