import axiosInstance from './axios';
import config from './config';

export const fetchReasonCodes = async () => {
  try {
    const response = await axiosInstance.get(config.endpoints.reason_codes_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching reason codes:', error);
    throw error;
  }
};
