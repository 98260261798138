import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashBoard/DashBoard";

const Navigation = ({ accountInfo, onThemeChange, onLogout }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Dashboard
              accountInfo={accountInfo}
              onThemeChange={onThemeChange}
              onLogout={onLogout}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
