import axiosInstance from './axios';
import config from './config';

export const fetchMetrics = async (selectedDate) => {
  try {
    const response = await axiosInstance.get(
      `${config.endpoints.metrics_url}?SelectedDate=${selectedDate}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching metrics:', error);
    throw error;
  }
};
